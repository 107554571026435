<template>
  <div>
    <SideBar />
    <HeaderData />
  </div>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import HeaderData from "@/components/HeaderData.vue";

export default {
  components: {
    SideBar,
    HeaderData,
  },
  data() {
    return {};
  },
};
</script>
