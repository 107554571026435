<template>
  <div class="custom-background fill-height" >
  <v-row justify="center">
      <v-col cols="12" sm="8" md="4" class="mt-16" align="center">
        <img src="logo.png" alt="Company Logo" style="max-width: 100%; height: 90px; margin-top: 100px" />
      </v-col>
    </v-row>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="4" style="margin-top: 70px;">
        <v-card class="elevation-12">
          <v-toolbar color="#FFCC80" dark flat>
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                label="Email"
                v-model="email"
                prepend-icon="mdi-email"
                type="email"
                variant="outlined"
                required
              ></v-text-field>
              <v-text-field
                label="Password"
                v-model="password"
                prepend-icon="mdi-lock"
                type="password"
                variant="outlined"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!isLoading" color="dark" text @click="login">Login</v-btn>
          <v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
  <v-snackbar v-model="snackbar" :timeout="timeout" location="top right">
    {{ snackbarText }}
  </v-snackbar>

  
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      snackbar: false,
      snackbarText: "",
      timeout: 5000,
      isLoading: false
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      const credentials = {
        email: this.email,
        password: this.password,
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_URL}/api/login`,
          credentials
        );

        const data = response.data;
        if (data.flag === true) {
          this.snackbarText = data.message || "Logged in successfully!";
          this.snackbar = true;
          setTimeout(() => {
            localStorage.setItem("userToken", data.token);
            localStorage.setItem("userData", JSON.stringify(data.user));
            this.$router.push("/");
          }, 1000);
        } 
      } catch (error) {
        this.isLoading = false;
        if (error.response) {
          this.snackbarText =
            error?.response?.data?.error ||
            "Something Went Wrong.";
        } else {
          this.snackbarText = "Something Went Wrong.";
        } 
        this.snackbar = true;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
  .custom-background {
    background-color: #FFF3E0;
  }


</style>
