<template>
  <v-navigation-drawer permanent>
    <img src="logo.png" alt="Company Logo" :style="logoStyle" />
    <v-divider class="mt-4"></v-divider>
    <v-list>
      <v-list-item @click="navigateTo('/')" :class="{ 'active-item': activeItem === '/' }">
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Dashboard"
          class="v-list-item-title"
        ></v-list-item>
      </v-list-item>

      <v-list-item @click="navigateTo('/categories')" :class="{ 'active-item': activeItem === '/categories' }" class="v-list-item-title">
        <v-list-item prepend-icon="mdi-plus" title="Category" class="custom-list-item"></v-list-item>
      </v-list-item>

      <v-list-item @click="navigateTo('/medicines')" :class="{ 'active-item': activeItem === '/medicines' }" class="v-list-item-title">
          <v-list-item
          prepend-icon="mdi-hospital-box"
          title="Medicine"
        ></v-list-item>
      </v-list-item>

      <v-list-item @click="navigateTo('/blogs')" :class="{ 'active-item': activeItem === '/blogs' }" class="v-list-item-title">
        <v-list-item
          prepend-icon="mdi-post-outline"
          title="Blogs"
          class="custom-list-item"
        ></v-list-item>
      </v-list-item>

      <v-list-item @click="navigateTo('/inquiry')" :class="{ 'active-item': activeItem === '/inquiry' }" class="v-list-item-title">
        <v-list-item
          prepend-icon="mdi-comment-question-outline"
          title="Inquiry"
          class="custom-list-item"
        ></v-list-item>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data(){
    return{
      activeItem: null,
    }
  },
  mounted() {
    this.activeItem = this.$route.path; 
  },
  computed: {
    logoStyle() {
      return {
        maxWidth: '100%',
        height: '41px',
        paddingLeft: '37px',
        position: 'relative',
        top: '10px'
      };
    }
  },
  methods: {
    navigateTo(routePath) {
      this.activeItem = routePath;
      this.$router.push(routePath);
    },
  },
};
</script>

<style scoped>
.active-item {
  background-color: #0277BD; 
  color: white; 
}

.v-list-item__content .v-list-item-title{
  font-weight: 900 !important;
}
</style>
