<template>
  <div>
      <v-select
        v-model="selectedCategory"
        :items="categories"
        item-title="name"
        item-value="id"
        :label="labelName || 'Parent Category'"
        clearable
        chips
        multiple
        @update:modelValue="handleCategoryChange"
      ></v-select>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    initialData: {
      type: Array,
      default: () => [],
    },
    initialDataNames: {
      type: Array,
      default: () => [],
    },
    categoryId: Number || String,
    labelName: String
  },
  data() {
    return {
      selectedCategory: [],
      categories: [],
    };
  },
  async created() {
    try {
      const params = {
        categoryId: this.categoryId
      }
      const response = await axios.get(
        `${process.env.VUE_APP_URL}/api/medicinesCategories`, { params }
      );
      if (Array.isArray(response.data.categories)) {
        this.categories = response.data.categories.map((cat) => ({
          id: cat.id, 
          name: cat.name,
        }));

        this.selectedCategory = this.initialDataNames.map(name =>
          this.categories.find(cat => cat.name === name)?.id
        ).filter(id => id != null);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  },
  methods: {
    handleCategoryChange(selected) {
      this.$emit("category-selected", selected ? selected : null);
    },
  },
};
</script>
