<template>
  <div>
    <div v-if="needsReadMore">
      <p> {{ stripTags(displayText) }}</p>
      <span class="truncate-text" @click="openDialog">...Read More</span>
    </div>
    <div v-else>
      <p>{{ stripTags(text) }}</p>
    </div>
    <v-dialog v-model="dialogVisible" max-width="1000px" min-height="500px">
      <v-card >
        <v-toolbar class="toolbar-position">
          <v-toolbar-title >
            <v-card-title >{{
              title
            }}</v-card-title>
          </v-toolbar-title>
        </v-toolbar>
        <div style="max-height: 400px; overflow-y: auto">
            <v-card-text class="mx-5" v-html="fullText"></v-card-text>
       </div>
        <v-card-actions style="position: sticky; bottom: 0; background: white;">
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    title: String,
    flag: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    displayText() {
      if (!this.text) return '';

      const isShortText = this.text.split(' ').length <= 3 || this.text.length <= this.maxLength;
       return isShortText ? this.text : this.text.substring(0, this.maxLength) + "...";
    },
    needsReadMore() {
      // if(this.flag) {
      //   return this.text && (this.text.split(' ').length > this.maxLength);
      // } 
      return this.text && (this.text.length > this.maxLength);
    },
    fullText() {
      return this.text || ''; 
    }
  },
  methods: {
    stripTags(htmlString) {
      if (htmlString) {
        const doc = new DOMParser().parseFromString(htmlString, "text/html");
        return doc.body.textContent || "";
      }
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.truncate-text {
  cursor: pointer;
  color: #1976d2;
}

.toolbar-position{
  position: sticky; 
  top: 0; background-color: #0277BD; 
  color: white
}

.v-card-text{
  height: 320px 
}
</style>
