<template>
  <div>
    <SideBar/>
    <HeaderData />
    <v-row align="center">
      <v-col cols="12" sm="6" md="6" lg="6" xl="3">
        <v-text-field
          v-model="search"
          label="Search"
          class="mt-5 ml-4"
          style="width: 300px"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" >
        <v-select
          v-model="selectedCategory"
          outlined
          label="Select Product Category"
          multiple
          hide-details
          clearable
          class="mt-5"
          item-title="name"
          item-value="id"
          style="width: 300px; margin-left: -43px"
          :items="categories"
          >
          <template v-slot:selection="{ item, index }">
            <v-chip small v-if="index === 0"
              ><span>{{ item.title }}</span></v-chip
            >
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedCategory.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" xs="4" lg="4" md="3 " xl="2">
        <v-switch
          label="Active"
          color="blue"
          inset
          class="mt-7 ml-5"
          v-model="filterActive"
          @click="toggleActiveFilter"
        ></v-switch>
      </v-col>

      <v-col cols="12" xs="4" lg="3" md="3" xl="2">
        <v-switch
          label="In Stock"
          color="blue"
          inset
          class="mt-7 ml-13"
          v-model="filterStock"
          @click="toggleStockFilter"
        ></v-switch>
      </v-col>

      <v-col cols="12" lg="3" xl="2" md="3" class="text-right">
        <v-btn
          @click="openDialog"
          style="height: 40px; width: 110px;"
          color="primary"
          rounded="xl"
          class="mt-n1 mr-3"
        >
          <v-icon left>mdi-plus</v-icon>
          Add
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogVisible" max-width="800px">
      <v-card>
        <v-toolbar color="#0277BD" :style="{ fontSize: '25px', paddingLeft:'20px'}" dark flat>
          {{ formData.id ? "Edit" : " Add" }} Medicine
          </v-toolbar>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="formData.name"
              :rules="nameRules"
              label="Name"
              required
            >
            </v-text-field>

            <CategoriesDropdown
              :initialData="formData.categoryIds"
              :initialDataNames="formData.categoriesNames"
              :labelName="'Category'"
              @category-selected="handleCategorySelected"
              class="mt-3"
            ></CategoriesDropdown>
            <div v-if="v$.formData.categoryIds.$error" class="mb-2 ml-3 error-message">Category is required!</div>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="formData.pharmaceuticalForm"
                  label="Pharmaceutical Form"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="formData.innovatorBrand"
                  label="Brand Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="formData.strength"
                  label="Strength"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="formData.pack"
                  label="Pack"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.therapeuticUse"
                  label="Therapeutic Use"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="formData.instructions" label="Instructions"></v-textarea>
              </v-col>
            </v-row>
            <label for="editor">Description</label>
            <Editor
              v-model="formData.description"
              editorStyle="height: 320px"
            />
            <div v-if="v$.formData.description.$error" class="mt-2 ml-5 error-message">Description is required!</div>
            <v-row class="mt-2">
              <v-col cols="4">
                <v-switch
                  v-model="formData.inStock"
                  color="primary"
                  inset
                  label="Stock"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="formData.isActive"
                  color="primary"
                  inset
                  label="Active"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="formData.isFavourite"
                  color="primary"
                  label="Favourite"
                  inset
                ></v-switch>
              </v-col>
            </v-row>
            <!-- <v-file-input accept="image/*" label="File input" @change="handleFileUpload" multiple></v-file-input> -->
            <label class="file-input-label mb-2" style="background-color: #0277BD;">
                Upload Image
                <input 
                  type="file" 
                  :key="fileInputKey"
                  accept="image/*" 
                  label="Image Input" 
                  @change="handleFileUpload"
                  class="file-input"
                  >
              </label>
              <div v-if="isImageSelected && selectedImage && selectedImage.length < 1" class="mb-2 ml-5 error-message">
                  Image is required!
              </div>
              <v-card variant="tonal" v-if="selectedImage && selectedImage.length">
                <div  class="d-flex justify-space-between my-3 mx-2">
                  <div class="mt-2">
                    {{ selectedImage[0].name }}
                  </div>
                    <div>
                      <v-btn
                      @click="removeImage"
                        color="red"
                        >Remove</v-btn
                      >
                    </div>
                </div>
              </v-card>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="!isLoading" color="primary" @click="validate">{{
              formData.id ? "Update" : "Save"
            }}</v-btn>
            <span v-if="isLoading" class="mr-2">Please Wait</span>
            <v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>

            <v-btn v-if="!isLoading" @click="closeDialog">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialogVisible" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline mt-4">Confirm Delete</v-card-title>
        <v-card-text
          >Are you sure you want to delete this Medicine?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isLoading"
            color="darken-1"
            text
            @click="confirmDeleteDialogVisible = false"
            >Cancel</v-btn
          >
          <v-btn v-if="!isLoading" color="red darken-1" text @click="confirmDelete">Delete</v-btn>
          <span v-if="isLoading" class="mr-2">Please Wait</span> 
          <v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmActiveDialogVisible" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline mt-4">Confirm In-Active Medicine</v-card-title>
        <v-card-text
          >Are you sure you want to in active this Medicine?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isLoading"
            color="darken-1"
            text
            @click="cancelActive"
            >Cancel</v-btn
          >
          <v-btn v-if="!isLoading" color="red darken-1" text @click="toggleActiveStatus">In Active</v-btn>
          <span v-if="isLoading" class="mr-2">Please Wait</span> 
          <v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="headers" :items="medicines.data" class="mt-2">
      <template v-slot:item.description="{ item }">
        <ReadMore v-if="item.description" :text="item.description" :maxLength="30" :title="'Medicine Description'"></ReadMore>
      </template>
      <template v-slot:item.instructions="{ item }">
        <ReadMore v-if="item.instructions" :text="item.instructions" :maxLength="30" :title="'Medicine Instruction'"></ReadMore>
      </template>
      <template v-slot:item.therapeuticUse="{ item }">
        <ReadMore v-if="item.therapeuticUse" :text="item.therapeuticUse" :maxLength="30" :title="'Medicine Therapeutic Use'"></ReadMore>
      </template>
      <template v-slot:item.categoryNames="{ item }">
        <ReadMore :text="item.categoryNames" :maxLength="30" :flag="true" :title="'Medicine Categories'"></ReadMore>
      </template>
      <template #bottom>
        <v-pagination
          v-model="pagination.currentPage"
          :length="pagination.lastPage"
          :total-visible="6"
          @input="handlePageChange($event)"
        ></v-pagination>
      </template>

      <template v-slot:item.image="{ item }">
        <img :src="getImageUrl(item.image)" alt="" class="image-set" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon color="blue" @click="editMedicine(item)">mdi-pencil</v-icon>
        <v-icon class="ml-3" color="red" @click="deleteMedicine(item)">mdi-delete</v-icon>
      </template>
      <template #item.isActive="{ item }">
        <v-switch
          v-model="item.isActive"
          @click.prevent="changeActiveValue(item)"
          color="primary"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
  <v-snackbar v-model="snackbarVisible" location="top right">
    {{ snackbarText }}
  </v-snackbar>
</template>
<script>
import axios from "axios";
import CategoriesDropdown from "@/components/MedicinesCategoriesDropdown.vue";
import SideBar from "../components/SideBar.vue";
import { mapActions } from "vuex";
import HeaderData from "@/components/HeaderData.vue";
import Editor from "primevue/editor";
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ReadMore from "./ReadMore.vue";

export default {
  components: {
    CategoriesDropdown,
    SideBar,
    HeaderData,
    Editor,
    ReadMore
  },
  data() {
    return {
      categories: [],
      filterStock: false,
      filterActive: false,
      selectedCategory: [],
      isLoading: false,
      isActiveSwitch: false,
      fileInput: null,
      fileInputKey: 0,
      isImageSelected: false,
      selectedImage: [],
      selectedFile: [],
      search: "",
      dialogVisible: false,
      formValid: false,
      categoryValid: false,
      valid: true,
      name: "",
      nameRules: [(v) => !!v || "Name is required!"],
      description: "",
      descriptionRules: [(v) => !!v || "Description is required!"],
      formData: {
        id: "",
        name: "",
        description: "",
        categoryIds: "",
        pharmaceuticalForm: "",
        innovatorBrand: "",
        strength: "",
        instructions: "",
        pack: "",
        therapeuticUse: "",
        model: true,
        inStock: false,
        isFavourite: false,
        isActive: false,
        image: null,
      },

      headers: [
        {
          text: "Name",
          title: "Name",
          value: "name",
          sortable: true,
          minWidth: "300px",
        },
        {
          text: "description",
          title: "Description",
          value: "description",
          sortable: true,
          minWidth: "300px",
        },
        // {
        //   text: "categoryName",
        //   title: "Category",
        //   value: "categoryName",
        //   sortable: true,
        //   minWidth: "200px",
        // },
        {
          text: "categoryNames",
          title: "Categories",
          value: "categoryNames",
          sortable: true,
          minWidth: "200px",
        },
        {
          text: "pharmaceuticalForm",
          title: "Pharmaceutical Form",
          value: "pharmaceuticalForm",
          sortable: true,
          minWidth: "200px",
        },
        
        {
          text: "innovatorBrand",
          title: "Brand Name",
          value: "innovatorBrand",
          sortable: true,
          minWidth: "200px",
        },
        {
          text: "strength",
          title: "Strength",
          value: "strength",
          sortable: true,
          minWidth: "200px",
        },
        {
          text: "instructions",
          title: "Instructions",
          value: "instructions",
          sortable: true,
          minWidth: "300px",
        },
        {
          text: "pack",
          title: "Pack",
          value: "pack",
          sortable: true,
          minWidth: "200px",
        },
        {
          text: "therapeuticUse",
          title: "Therapeutic Use",
          value: "therapeuticUse",
          sortable: true,
          minWidth: "300px",
        },
        {
          text: "inStock",
          title: "Stock",
          value: "inStock",
          sortable: true,
          minWidth: "100px",
        },
        {
          text: "isFavourite",
          title: "Favourite",
          value: "isFavourite",
          sortable: true,
          minWidth: "100px",
        },
        {
          text: "isActive",
          title: "Active",
          value: "isActive",
          sortable: true,
          minWidth: "100px",
        },
        {
          text: "image",
          title: "Image",
          value: "image",
          minWidth: "100px",
        },
        {
          text: "Actions",
          title: "Actions",
          value: "actions",
          sortable: false,
          minWidth: "100px",
        },
      ],
      confirmDeleteDialogVisible: false,
      confirmActiveDialogVisible: false,
      selectedmedicine: null,
      sortBy: [],
      sortDesc: [],
      inStock: false,
      isFavourite: false,
      snackbarVisible: false,
      snackbarText: "",
      pagination: {
        lastPage: null,
        currentPage: 1,
        total: null,
        perPage: 10,
      },
    };
  },

  validations() {
    return {
      formData: {
        description: { required },
        categoryIds: { required },
      }
    }
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  mounted() {
    this.fetchCategories();
  },

  created() {
    this.fetchmedicines(
      this.pagination.currentPage,
      this.pagination.perPage,
      this.search,
      this.categoriesData
    );
  },

  watch: {
    "pagination.currentPage"(newPage) {
      if (typeof newPage !== "undefined") {
        this.fetchmedicines(newPage, this.pagination.perPage, this.search);
      }
    },

    search(newSearch, oldSearch) {
      if (newSearch !== oldSearch) {
        this.pagination.currentPage = 1;
        this.$nextTick(() => {
          this.fetchmedicines(
            this.pagination.currentPage,
            this.pagination.perPage,
            newSearch,
            this.categoriesData
          );
        });
      }
    },
    selectedCategory(newVal, oldVal) {
      this.categoriesData = newVal;
      if (newVal !== oldVal) {
        this.resetPaginationAndFetchMedicines();
      }
    },
  },

  beforeRouteLeave() {
    this.pagination.currentPage = 1;
  },

  computed: {
    medicines() {
      const medicinesData = this.$store.state.medicines;
      this.pagination.lastPage = medicinesData.last_page;
      this.pagination.currentPage = medicinesData.current_page;
      this.pagination.perPage = medicinesData.per_page;
      this.pagination.total = medicinesData.total;

      return this.$store.state.medicines;
    },
  },

  methods: {
    ...mapActions(["fetchMedicines"]),

    async fetchCategories() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/api/categories`
        );

        if (Array.isArray(response.data.categories)) {
          this.categories = response.data.categories.map((cat) => ({
            id: cat.id, 
            name: cat.name,
          }));
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },

    getImageUrl(imageJson) {
      try {
        const imageData = JSON.parse(imageJson) ?? [];
        return imageData[0]?.url;
      } catch (error) {
        console.error("Error parsing image JSON:", error);
        return "#";
      }
    },

    cancelActive() {
      this.confirmActiveDialogVisible = false;
      this.fetchFilteredMedicines();
    },

    changeActiveValue(item) {
      this.selectedMedicine = item;
      if(item.isActive == true) {
        this.confirmActiveDialogVisible = true;
      } else {
        this.toggleActiveStatus();
      }
    },

    async toggleActiveStatus() {
      let item = this.selectedMedicine;
      this.isLoading = true;
      try {
        let formData = {
          id: item.id,
          isActive: !item.isActive,
        }
        const response = await axios.post(
          `${process.env.VUE_APP_URL}/api/medicine-change-active`,
          formData
        );
        this.fetchFilteredMedicines();
        this.confirmActiveDialogVisible = false;
        this.snackbarText = "Medicine updated successfully";
        this.snackbarVisible = true;
      } catch (error) {
        this.confirmActiveDialogVisible = false;
        this.isLoading = false;
        console.error("Error toggling category active status:", error);
      } finally {
        this.confirmActiveDialogVisible = false;
        this.isLoading = false;
      }
    },

    stripTags(htmlString) {
      if (htmlString) {
        const doc = new DOMParser().parseFromString(htmlString, "text/html");
        return doc.body.textContent || "";
      }
    },
    resetPaginationAndFetchMedicines() {
      this.pagination.currentPage = 1;
      this.fetchFilteredMedicines();
    },

    toggleActiveFilter() {
      this.filterActive = !this.filterActive;
      this.pagination.currentPage = 1;
      this.fetchFilteredMedicines();
    },

    toggleStockFilter() {
      this.filterStock = !this.filterStock;
      this.pagination.currentPage = 1;
      this.fetchFilteredMedicines();
    },

    fetchFilteredMedicines() {
      this.fetchMedicines({
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        search: this.search,
        category: this.selectedCategory,
        isActive: this.filterActive,
        inStock: this.filterStock,
      });
    },

    async fetchmedicines(page, perPage, search = "", category = []) {
      await this.$store.dispatch("fetchMedicines", {
        page: page,
        perPage: perPage,
        search: search,
        category: category,
        isActive: this.filterActive,
        inStock: this.filterStock,
      });
    },

    async validate() {
      this.isImageSelected = true;
      const validate = await this.$refs.form.validate();
      await this.v$.$validate();
      if (validate.valid && !this.v$.$error && this.selectedImage.length) {
        this.saveMedicine();
      }
    },
    // imageUrl(image) {
    //   const basUrl = process.env.VUE_APP_URL;
    //   console.log("this.selectewwwwdFile", this.selectedFile);
    //   const imageUrl = `${basUrl}/storage/${image}`;
    //   return imageUrl;
    // },
    openDialog() {
      this.dialogVisible = true;
      this.formValid = false;
      this.categoryValid = false;
    },

    deleteMedicine(item) {
      this.selectedMedicine = item;
      this.confirmDeleteDialogVisible = true;
    },


    handleFileUpload(event) {
      // this.selectedFile = [];
      // this.selectedImage = [];
      // this.selectedFile = event.target.files[0];
      // const selectedFiles = event.target.files;
      // var selectedFiles = event.target.files;
      var selectedFiles = event.target.files || event;
      this.selectedFile = [];
      this.selectedImage = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        this.selectedFile.push(selectedFiles[i]);
        this.selectedImage.push({
          name: selectedFiles[i].name
        });
        // this.selectedImage.push({
        //   name: selectedFiles[i].name,
        //   file: selectedFiles[i],
        //   isNew: true
        // });
      }
    },

    closeDialog() {
      this.dialogVisible = false;
      this.formData = {
        id: "",
        name: "",
        description: "",
        categoryIds: "",
        pharmaceuticalForm: "",
        innovatorBrand: "",
        strength: "",
        instructions: "",
        pack: "",
        therapeuticUse: "",
        inStock: false,
        isFavourite: false,
        isActive: false,
        image: null,
      };
      this.selectedFile = [];
      this.selectedImage = []; 
      this.isImageSelected = false;
      if (this.v$) {
        this.v$.$reset();
       }
    },
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
      this.fetchmedicines(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.search,
        this.categoriesData
      );
    },

    removeImage() {
      this.isImageSelected = true;
      this.selectedFile = [];
      this.selectedImage = [];
      this.fileInput = null;
      this.fileInputKey++; 
    },

    async saveMedicine() {
      this.isLoading = true;
      let formData = {
        name: this.formData.name,
        description: this.formData.description,
        categoryIds: this.formData.categoryIds,
        pharmaceuticalForm: this.formData.pharmaceuticalForm,
        innovatorBrand: this.formData.innovatorBrand,
        strength: this.formData.strength,
        instructions: this.formData.instructions,
        pack: this.formData.pack,
        therapeuticUse: this.formData.therapeuticUse,
        inStock: this.formData.inStock,
        isFavourite: this.formData.isFavourite,
        isActive: this.formData.isActive,
      };

      if (this.formData.id) {
        formData.id = this.formData.id;
      }

      if (this.selectedFile && this.selectedFile.length) {
          formData.image = this.selectedFile;
        }

      if (formData.id) {
        await this.$store.dispatch("updateMedicine", formData);
        this.fetchMedicines({
          page: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          search: this.search,
          category: this.selectedCategory,
          isActive: this.filterActive,
          inStock: this.filterStock,
        });
      } else {
        await this.$store.dispatch("addMedicine", {
          formData: formData,
          page: this.pagination.currentPage,
          perPage: this.pagination.perPage,
        });
      }
      try {
        this.selectedFile = [];
        this.isLoading = false;
        this.snackbarText = formData.id
          ? "Medicine updated successfully"
          : "Medicine added successfully";
        this.snackbarVisible = true;
        this.closeDialog();
      } catch (error) {
        this.isLoading = false;
        console.error("Error saving medicine:", error.response.data);
        this.snackbarText = "Error saving medicine";
        this.snackbarVisible = true;
      }
    },

    handleCategorySelected(selectedCategory) {
      this.formData.categoryIds = selectedCategory;
      this.categoryValid = !!selectedCategory;
      this.checkFormValidity();
    },
    checkFormValidity() {
      this.formValid =
        this.categoryValid &&
        this.nameRules[0](this.formData.name) === true &&
        this.descriptionRules[0](this.formData.description) === true;
    },

    editMedicine(item) {
      // this.formData = {
      //   id: item.id,
      //   name: item.name,
      //   description: item.description,
      //   categoryIds: item.categoryIds,
      //   pharmaceuticalForm: item.pharmaceuticalForm,
      //   innovatorBrand: item.innovatorBrand,
      //   strength: item.strength,
      //   instructions: item.instructions,
      //   pack: item.pack,
      //   therapeuticUse: item.therapeuticUse,
      //   inStock: item.inStock,
      //   isFavourite: item.isFavourite,
      //   isActive: item.isActive,
      //   image: null,
      // };
      // this.selectedImage = JSON.parse(item.image);
      // this.selectedImage = item.image.map(image => {
      //   return { name: image.name, isNew: false }; 
      // });
      this.formData = { ...item };
      const imagesArray = JSON.parse(item.image) || [];
      this.selectedImage = imagesArray.map(image => {
        return { name: image.name, isNew: false }; // Mark as not new for existing images
      });
      // this.selectedFile = [];
      this.dialogVisible = true;
    },

    async confirmDelete() {
      try {
        this.isLoading = true;
        const medicineId = this.selectedMedicine.id;
        await this.$store.dispatch("deleteMedicine", medicineId);
        this.$store.commit("DELETE_MEDICINE", medicineId);
        this.confirmDeleteDialogVisible = false;
        this.pagination.currentPage = 1;
        this.selectedMedicine = null;
        this.isLoading = false;
        this.snackbarText = "Medicine deleted successfully!";
        this.snackbarVisible = true;
      } catch (error) {
        this.isLoading = false;
        console.error("Error deleting medicine:", error);
        this.snackbarText = "Error occurred while deleting the medicine.";
        this.snackbarVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.image-set {
  margin-top: 5px;
  max-height: 85px;
  max-width: 85px;
}
.truncate-text {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-message {
  font-size: small;
  color: #B00020;
}

.file-input-label {
  background-color: #f6f6f6;
  /* color: white; */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  color: white;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.v-table--density-default {
    --v-table-header-height: 60px;
    --v-table-row-height: 75px;
}

</style>
