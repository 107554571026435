import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Category from "../views/Category.vue";
import Medicine from "../views/Medicine";
import Blog from "../views/Blog";
import LoginPage from "../views/LoginPage";
import Inquiry from "../views/Inquiry";

const routes = [
  { path: "/", name: "Dashboard", component: Dashboard },
  { path: "/login", name: "Login", component: LoginPage },
  { path: "/categories", name: "Categories", component: Category },
  { path: "/medicines", name: "Medicines", component: Medicine },
  { path: "/blogs", name: "Blogs", component: Blog },
  { path: "/inquiry", name: "Inquiry", component: Inquiry },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
