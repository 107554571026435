<template>
    <div>
        <v-select
          v-model="selectedCategory"
          :items="categories"
          item-title="name"
          item-value="id"
          :label="labelName || 'Parent Category'"
          clearable
          @update:modelValue="handleCategoryChange"
        ></v-select>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    props: {
      initialData: Object,
      categoryId: Number || String,
      labelName: String
    },
    data() {
      return {
        selectedCategory: this.initialData?.category || null,
        categories: [],
      };
    },
    async created() {
      try {
        const params = {
          categoryId: this.categoryId
        }
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/api/categoriesData`, { params }
        );
        if (Array.isArray(response.data.categories)) {
          this.categories = response.data.categories.map((cat) => ({
            id: cat.id, 
            name: cat.name,
          }));
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    methods: {
      handleCategoryChange(selected) {
        this.$emit("category-selected", selected ? selected : null);
      },
    },
  };
  </script>
  