<template>
  <div>
    <v-row align="center">
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="9">
            <h2 class="mt-3 ml-4">{{ currentRoute }}</h2>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn text @click="showLogoutConfirmation" class="mt-3 mr-5" color="#E1E5E7"
              >Logout</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
  </div>
  <ConfirmationDialog
    v-model="showConfirmDialog"
    title="Logout"
    text="Are you sure, You want to Logout?"
    @confirm="logout"
    @cancel="handleCancel"
  />
</template>

<script>
import ConfirmationDialog from "./Common/ConfirmationDialog.vue";
export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      currentRoute: null,
      showConfirmDialog: false,
    };
  },

  created() {
    this.currentRoute = this.$route.name;
  },

  methods: {
    showLogoutConfirmation() {
      this.showConfirmDialog = true;
    },

    logout() {
      this.showConfirmDialog = false;
      localStorage.clear();
      this.confirmDialog = false;
      this.$router.push("/login");
    },

    handleCancel() {
      this.showConfirmDialog = false;
    },
  },
};
</script>
