import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./plugins/router";
import "vuetify/dist/vuetify.css";
import store from "./store";
loadFonts();
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("userToken") !== null;

  if (!isAuthenticated) {
    if (to.name !== "Login") {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

createApp(App).use(vuetify).use(router).use(store).mount("#app");
