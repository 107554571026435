<template>
    <v-dialog v-model="dialog" persistent max-width="450px">
      <v-card>
        <v-card-title class="headline mt-2">{{ title }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color=" darken-1" text @click="cancel">Cancel</v-btn>
          <v-btn color="primary darken-1" text @click="confirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      value: { type: Boolean, default: false }, 
      title: { type: String, default: 'Confirm' },
      text: { type: String, default: 'Are you sure?' },
    },
    data() {
      return {
        dialog: this.value,
      };
    },
    watch: {
      value(newVal) {
        this.dialog = newVal;
      },
      dialog(newVal) {
        this.$emit('input', newVal);
      },
    },
    methods: {
      cancel() {
        this.$emit('cancel');
        this.close();
      },
      confirm() {
        this.$emit('confirm');
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
    },
  };
  </script>
  