import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    categories: [],
    medicines: [],
    blogs: [],
    inquiries: [],
  },
  computed: {
    categories() {
      console.log("fgvds", this.$store.state.categories);
      return this.$store.state.categories;
    },

    medicines() {
      return this.$store.state.medicines;
    },

    blogs() {
      return this.$store.state.blogs;
    },
    inquiries() {
      return this.$store.state.inquiries;
    },
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_MEDICINES(state, medicines) {
      state.medicines = medicines;
    },
    SET_BLOGS(state, blogs) {
      state.blogs = blogs;
    },
    SET_INQUIRIES(state, inquiries) {
      state.inquiries = inquiries;
    },
    EDIT_CATEGORY(state, updatedCategory) {
      const index = state.categories.data.findIndex(
        (category) => category.id === updatedCategory.id
      );
      if (index !== -1) {
        state.categories.data.splice(index, 1, updatedCategory);
      }
    },
    EDIT_MEDICINES(state, updatedMedicine) {
      const index = state.medicines.data.findIndex(
        (medicine) => medicine.id === updatedMedicine.id
      );
      if (index !== -1) {
        state.medicines.data.splice(index, 1, updatedMedicine);
      }
    },
    EDIT_INQUIRIES(state, updatedInquiry) {
      const index = state.inquiries.data.findIndex(
        (inquiry) => inquiry.id === updatedInquiry.id
      );
      if (index !== -1) {
        state.inquiries.data.splice(index, 1, updatedInquiry);
      }
    },

    DELETE_MEDICINE(state, medicineId) {
      if (state.medicines.data) {
        state.medicines.data = state.medicines.data.filter(
          (medicine) => medicine.id !== medicineId
        );
      }
    },

    DELETE_CATEGORY(state, categoryId) {
      if (state.categories.data) {
        state.categories.data = state.categories.data.filter(
          (category) => category.id !== categoryId
        );
      }
    },
    DELETE_INQUIRY(state, inquiryId) {
      if (state.categories.data) {
        state.inquiries.data = state.inquiries.data.filter(
          (inquiry) => inquiry.id !== inquiryId
        );
      }
    },
  },
  actions: {
    async fetchItems({ commit }, { page, perPage, search = "" }) {
      const currentPage = page || 1;
      const itemsPerPage = perPage || 10;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/api/categories?page=${currentPage}&perPage=${itemsPerPage}&search=${search}`
        );
        commit("SET_CATEGORIES", response.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },

    async fetchMedicines(
      { commit },
      {
        page,
        perPage,
        search = "",
        category = "",
        isActive = false,
        inStock = false,
      }
    ) {
      let categoryQuery = "";
      if (Array.isArray(category) && category.length) {
        categoryQuery = category.join(",");
      }
      const currentPage = page || 1;
      const itemsPerPage = perPage || 10;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/api/medicines?page=${currentPage}&perPage=${itemsPerPage}&search=${search}&category=${categoryQuery}&isActive=${isActive}&inStock=${inStock}`
        );
        commit("SET_MEDICINES", response.data.medicines);
      } catch (error) {
        console.error("Error fetching medicines:", error);
      }
    },

    async addItem({ dispatch }, { formData, page, perPage }) {
      try {
        await axios.post(`${process.env.VUE_APP_URL}/api/categories`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        dispatch("fetchItems", { page, perPage, search: "" });
      } catch (error) {
        console.error("Error adding category:", error);
      }
    },

    async addMedicine({ dispatch }, { formData, page, perPage }) {
      try {
        await axios.post(`${process.env.VUE_APP_URL}/api/medicines`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        dispatch("fetchMedicines", { page, perPage, search: "" });
        console.log("Medicine added successfully");
      } catch (error) {
        console.error("Error adding medicine:", error);
      }
    },

    async editItem({ commit }, updatedCategory) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_URL}/api/categories/${updatedCategory.id}/update`,
          updatedCategory,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Edit response:", response);
        commit("EDIT_CATEGORY", updatedCategory);
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },

    async updateMedicine({ commit }, updatedMedicine) {
      try {
        console.log("formDataaaaa", updatedMedicine);
        const response = await axios.post(
          `${process.env.VUE_APP_URL}/api/medicines/${updatedMedicine.id}/update`,
          updatedMedicine,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Update responsewwwwww:", response);
        commit("EDIT_MEDICINES", updatedMedicine);
      } catch (error) {
        console.error("Error updating medicine:", error);
      }
    },

    async deleteItem({ commit }, categoryId) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_URL}/api/categories/${categoryId}/delete`
        );
        console.log("Delete response:", response);
        commit("DELETE_CATEGORY", categoryId);
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    },

    async deleteMedicine({ commit }, medicineId) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_URL}/api/medicines/${medicineId}/delete`
        );
        commit("DELETE_MEDICINE", medicineId);
      } catch (error) {
        console.error("Error deleting medicine:", error);
      }
    },
    
    // Blog Action

    async fetchBlogs({ commit }, { page, perPage, search = "" }) {
      const currentPage = page || 1;
      const itemsPerPage = perPage || 10;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/api/blogs?page=${currentPage}&perPage=${itemsPerPage}&search=${search}`
        );
        commit("SET_BLOGS", response.data.blogs);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    },

    async addBlog({ dispatch }, { formData, page, perPage }) {
      try {
        await axios.post(`${process.env.VUE_APP_URL}/api/blogs`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
          
        dispatch("fetchBlogs", { page, perPage, search: "" });
      } catch (error) {
        console.error("Error adding blog:", error);
      }
    },

    async updateBlog({ commit }, updatedBlog) {
      try {
        console.log("updatedBlog", updatedBlog);
        const response = await axios.post(
          `${process.env.VUE_APP_URL}/api/blogs/${updatedBlog.id}/update`, updatedBlog,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Update blog:", response);
        // commit("EDIT_BLOGS", updatedBlog);
      } catch (error) {
        console.error("Error updating medicine:", error);
      }
    },

    async deleteBlog({ commit }, BlogId) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_URL}/api/blogs/${BlogId}/delete`
        );
        // commit("DELETE_BLOG", BlogId);
      } catch (error) {
        console.error("Error deleting blog:", error);
      }
    },

    // Inquiry Action'

    async fetchInquiries({ commit }, { page, perPage, search = "" }) {
      const currentPage = page || 1;
      const itemsPerPage = perPage || 10;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/api/inquiries?page=${currentPage}&perPage=${itemsPerPage}&search=${search}`
        );
        console.log("hello",response.data.inquiries);
        commit("SET_INQUIRIES", response.data.inquiries);
      } catch (error) {
        console.error("Error fetching inquiries:", error);
      }
    },

    async addInquiry({ dispatch }, { formData, page, perPage }) {
      try {
        await axios.post(`${process.env.VUE_APP_URL}/api/inquiries`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
          
        dispatch("fetchInquiries", { page, perPage, search: "" });
      } catch (error) {
        console.error("Error adding blog:", error);
      }
    },

    async updateInquiry({ commit }, updatedInquiry) {
      try {
        console.log("updatedInquiry", updatedInquiry);
        const response = await axios.post(
          `${process.env.VUE_APP_URL}/api/inquiries/${updatedInquiry.id}/update`, updatedInquiry,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Update inquiry:", response);
        // commit("EDIT_INQUIRIES", updatedInquiry);
      } catch (error) {
        console.error("Error updating inquiry:", error);
      }
    },

    async deleteInquiry({ commit }, InquiryId) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_URL}/api/inquiries/${InquiryId}/delete`
        );
        // commit("DELETE_INQUIRY", InquiryId);
      } catch (error) {
        console.error("Error deleting inquiry:", error);
      }
    },
  }
});
