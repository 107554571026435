<template>
    <div>
      <SideBar />
      <HeaderData />
      <v-row align="center">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="9">
              <v-text-field
                v-model="search"
                label="Search"
                style="width: 300px"
                class="mt-5 ml-4"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="3" class="text-right">
              <v-btn
                @click="openDialog"
                class="mt-5 mr-3"
                style="height: 40px; width: 110px;"
                color="primary"
                rounded="xl"
              >
                <v-icon left>mdi-plus</v-icon>
                Add
              </v-btn>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
  
      <v-dialog v-model="dialogVisible" max-width="800px">
        <v-card height="330px">
          <v-toolbar color="#0277BD" :style="{ fontSize: '25px', paddingLeft:'20px'}" dark flat>
            {{ formData.id ? "Edit" : " Add" }} Inquiry </v-toolbar>        
            <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text >
              <!-- <v-text-field
                v-model="formData.name"
                :rules="nameRules"
                label="Name"
                required
                :disabled=true
              >
              </v-text-field>
              <v-text-field
                v-model="formData.email"
                label="Email"
                required
                :disabled=true
              >
              </v-text-field>
              <v-text-field
                v-model="formData.mobileNumber"
                :rules="mobileNumberRules"
                label="Mobile Number"
                required
                :disabled="true"
                type="tel"
              >
              </v-text-field> -->
              <!-- <v-select
                v-model="formData.status"
                :items="statusOptions"
                label="Status"
                required
                return-object
                item-text="name"
                item-value="value"
              ></v-select> -->
              <v-select
                v-model="formData.status"
                :items="statusOptions"
                item-title="label"
                item-value="value"
                label="Status"
                class="my-5"
                required
              ></v-select>
              <!-- <v-text-field
                v-model="formData.subject"
                label="Subject"
                required
                :disabled="true"
              >
              </v-text-field>
              <label for="editor" >Description</label>
              <Editor
                v-model="formData.description"
                editorStyle="height: 320px"
              /> -->
              <!-- {{ v$.formData.description.$error }} -->
              <!-- <div v-if="v$.formData.description.$error" class="mt-2 ml-5 error-message">Description is required!</div>
              <v-text-field
                v-model="formData.country"
                label="Country"
                required
                :disabled="true"
              >
              </v-text-field>
              <v-text-field
                v-model="formData.interestedProduct"
                label="Interested Product"
                required
                :disabled="true"
              >
              </v-text-field> -->
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="!isLoading" color="primary" @click="validate" class="mt-16">{{
                formData.id ? "Update" : "Save"
              }}</v-btn>
              <span v-if="isLoading" class="mr-2 mt-16">Please Wait</span> 
              <v-progress-circular v-if="isLoading" class="mt-16" color="primary" indeterminate></v-progress-circular>
              <v-btn v-if="!isLoading" @click="closeDialog" class="mt-16">Cancel</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
  
      <v-dialog v-model="confirmDeleteDialogVisible" persistent max-width="400px">
        <v-card>
          <v-card-title class="headline mt-4">Confirm Delete</v-card-title>
          <v-card-text>Are you sure you want to delete this Inquiry?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isLoading"
              color="darken-1"
              text
              @click="confirmDeleteDialogVisible = false"
              >Cancel</v-btn
            >
            <v-btn v-if="!isLoading" color="red darken-1" text @click="confirmDelete">Delete</v-btn>
            <span v-if="isLoading" class="mr-2">Please Wait</span> 
            <v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="viewDialogVisible" max-width="800px" height="900">
      <v-card>
        <v-toolbar color="#0277BD" :style="{ fontSize: '25px', paddingLeft:'20px'}" dark flat>
        Inquiry Details</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <strong>Name:</strong> {{ inquiryDetails.name }}
              </v-col>
              <v-col cols="12">
                <strong>Email:</strong> {{ inquiryDetails.email }}
              </v-col>
              <v-col cols="12">
                <strong>Mobile number:</strong> {{ inquiryDetails.mobileNumber }}
              </v-col>
              <v-col cols="12">
                <strong>Status:</strong> {{ inquiryDetails.status }}
              </v-col>
              <!-- <v-col cols="12">
                <strong>Subject:</strong> {{ inquiryDetails.subject }}
              </v-col> -->
              <v-col cols="12">
                <strong>Country:</strong> {{ inquiryDetails.country }}
              </v-col>
              <v-col cols="12">
                <strong>Interested product:</strong> {{ inquiryDetails.interestedProduct }}
              </v-col>
              <v-col cols="12">
                <strong>Description:</strong> {{ stripTags(inquiryDetails.description) }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="viewDialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
      <v-data-table :headers="headers" :items="inquiry" class="mt-2">
        <template v-slot:item.description="{ item }">
          <ReadMore v-if="item.description" :text="item.description" :maxLength="55" :title="'Inquiry Description'"></ReadMore>
        </template>
        <template #bottom>
          <v-pagination
            v-model="pagination.currentPage"
            :length="pagination.lastPage"
            :total-visible="6"
            @input="handlePageChange($event)"
          ></v-pagination>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon style="margin-left: 50px;" color="blue" @click="editInquiry(item)">mdi-pencil</v-icon>
          <v-icon class="ml-3" color="green" @click="viewInquiryDetails(item)">mdi-eye</v-icon>
          <v-icon class="ml-3" color="red" @click="deleteInquiry(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.status="{ item }">
          <div>{{ capitalWord(item.status) }}</div>
        </template>
      </v-data-table>
    </div>
    <v-snackbar v-model="snackbarVisible" location="top right">
      {{ snackbarMessage }}
    </v-snackbar>
  </template>
  <script>
  import SideBar from "../components/SideBar.vue";
  import { mapActions } from "vuex";
  import ConfirmationDialog from "../components/Common/ConfirmationDialog.vue";
  import HeaderData from "@/components/HeaderData.vue";
  import Editor from "primevue/editor";
  import Datepicker from "vuejs3-datepicker";
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  import ReadMore from "./ReadMore.vue";
  import axios from 'axios';
  
  export default {
    components: {
      SideBar,
      ConfirmationDialog,
      HeaderData,
      Editor,
      Datepicker,
      ReadMore
    },
  
    data() {
      return {
        search: "",
        dialogVisible: false,
        valid: true,
        isLoading: false,
        name: "",
        nameRules: [(v) => !!v || "Name is required!"],
        description: "",
        descriptionRules: [(v) => !!v || "Description is required!"], 
        mobileNumberRules: [
          v => !!v || 'Mobile number is required',
          v => (v && v.length >= 10) || 'Mobile number must be at least 10 digits',
        ],  
        viewDialogVisible: false,
        inquiryDetails: {},   
        formData: {
          id: "",
          name: "",
          email:"",
          mobileNumber: "",
          status:"",
          subject: "",
          description: "",
          country: "",
          interestedProduct: "",  
        },
        statusOptions: [
          { label:"Open", value:"open" },
          { label:"Close", value:"close" },
          { label:"In progress", value:"in-progress" }
        ],
        headers: [
          { text: "Name", title: "Name", value: "name", sortable: true },
          { text: "Email", title: "Email", value: "email", sortable: true },
          { text: "Mobile number", title: "Mobile number", value: "mobileNumber", sortable: true },
          { text: "Status", title: "Status", value: "status", sortable: true },
          { text: "Subject", title: "Subject", value: "subject", sortable: true },
          { text: "description", title: "Description", value: "description", sortable: true },
          { text: "Country", title: "Country", value: "country", sortable: true },
          { text: "Interested product", title: "Interested product", value: "interestedProduct", sortable: true },
          {
            text: "Actions",
            title: "Actions",
            value: "actions",
            sortable: false,
          },
        ],
        confirmDeleteDialogVisible: false,
        selectedInquiry: null,
        isDatePickerOpen: false,
        sortBy: [],
        sortDesc: [],
        snackbarVisible: false,
        snackbarMessage: "",
        pagination: {
          lastPage: null,
          currentPage: 1,
          total: null,
          perPage: 10,
        },
      };
    },
  
    validations() {
      return {
        formData: {
          description: { required },
        }
      }
    },
  
    setup() {
      const v$ = useVuelidate();
      return { v$ };
    },
  
    created() {
      this.fetchInquiryData(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.search
      );
    },
  
    watch: {
      "pagination.currentPage"(newPage) {
        if (typeof newPage !== "undefined") {
          this.fetchInquiryData(newPage, this.pagination.perPage, this.search);
        }
      },
  
      search(newSearch, oldSearch) {
        if (newSearch !== oldSearch) {
          this.pagination.currentPage = 1;
          this.$nextTick(() => {
            this.fetchInquiryData(
              this.pagination.currentPage,
              this.pagination.perPage,
              newSearch
            );
          });
        }
      },
    },
  
    beforeRouteLeave() {
      this.pagination.currentPage = 1;
    },
  
    computed: {
      inquiry() {
        const inquiryData = this.$store.state.inquiries;
        this.pagination.lastPage = inquiryData.last_page;
        this.pagination.currentPage = inquiryData.current_page;
        this.pagination.perPage = inquiryData.per_page;
        this.pagination.total = inquiryData.total;
        return this.$store.state.inquiries.data;
      },
    },
  
    methods: {
      ...mapActions(["fetchInquiries"]),
      async fetchInquiryData(page, perPage, search) {
        search = search || "";
        await this.$store.dispatch("fetchInquiries", {
          page: page,
          perPage: perPage,
          search: search,
        });
      },

      capitalWord(value) {
        if (!value) return '';
        value = value.toString().replace(/-/g, ' '); 
        return value.replace(/\b\w/g, function(char) { return char.toUpperCase(); });
      },

      async viewInquiryDetails(inquiry) {
        this.isLoading = true; 
        try {
          const response = await axios.get(`${process.env.VUE_APP_URL}/api/inquiries/${inquiry.id}`);
          this.inquiryDetails = response.data.inquiry; 
          this.viewDialogVisible = true; 
          this.isLoading = false; 
        } catch (error) {
          console.error("Error fetching inquiry details:", error);
          this.isLoading = false; 
        }
    },
  
      getImageUrl(imageJson) {
        try {
          const imageData = JSON.parse(imageJson) ?? [];
          return imageData[0]?.url;
        } catch (error) {
          console.error("Error parsing image JSON:", error);
          return "";
        }
      },
  
      stripTags(htmlString) {
        if(htmlString) {
          const doc = new DOMParser().parseFromString(htmlString, "text/html");
          return doc.body.textContent || "";
        }
      },
  
      async validate() {
        const validate = await this.$refs.form.validate();
        await this.v$.$validate();
        if (validate.valid && !this.v$.$error) {
          this.saveInquiry();
        }
      },
      openDialog() {
        this.dialogVisible = true;
      },
      closeDialog() {
        this.dialogVisible = false;
        this.formData = {
          id: "",
          name: "",
          description: "",
          mobileNumber: "",
          email: "",
          status: "",
          subject: "",
          country: "",
          interestedProduct: "",
        };
        if (this.v$) {
          this.v$.$reset();
         }
      },
      handlePageChange(newPage) {
        this.pagination.currentPage = newPage;
        this.fetchInquiryData(
          this.pagination.currentPage,
          this.pagination.perPage,
          this.search
        );
      },
      
  
      async saveInquiry() {
        this.isLoading = true;
       
        let formData = {
          name: this.formData.name,
          mobileNumber: this.formData.mobileNumber,
          email: this.formData.email,
          status: this.formData.status,
          subject: this.formData.subject,
          description: this.formData.description,
          country: this.formData.country,
          interestedProduct: this.formData.interestedProduct,
        };
  
        if (this.formData.id) {
          formData.id = this.formData.id;
        }
  
        if (formData.id) {
          await this.$store.dispatch("updateInquiry", formData);
          this.fetchInquiryData(
            this.pagination.currentPage,
            this.pagination.perPage,
            this.search
          );
        } else {
          await this.$store.dispatch("addInquiry", {
            formData: formData,
            page: this.pagination.currentPage,
            perPage: this.pagination.perPage,
          });
        }
  
        try {
          this.isLoading = false;
          this.snackbarMessage = this.formData.id
            ? "Inquiry updated successfully!"
            : "Inquiry added successfully!";
          this.snackbarVisible = true;
          this.closeDialog();
        } catch (error) {
          this.isLoading = false;
          console.error("Error saving inquiry:", error);
          this.snackbarMessage = "Error occurred while saving the inquiry.";
          this.snackbarVisible = true;
        }
      },
  
      editInquiry(item) {
        this.formData = {
          id: item.id,
          name: item.name,
          mobileNumber: item.mobileNumber,
          email: item.email,
          status: item.status,
          subject: item.subject,
          description: item.description,
          country: item.country,
          interestedProduct: item.interestedProduct,
        };
        this.dialogVisible = true;
      },
  
      deleteInquiry(item) {
        this.selectedInquiry = item;
        this.confirmDeleteDialogVisible = true;
      },
      async confirmDelete() {
          try {
            this.isLoading = true;
            const InquiryId = this.selectedInquiry.id;
            await this.$store.dispatch("deleteInquiry", InquiryId);
            await this.fetchInquiryData(this.pagination.currentPage, this.pagination.perPage, this.search);
            if (this.pagination.currentPage > this.pagination.lastPage) {
              this.pagination.currentPage = this.pagination.lastPage || 1; 
              
              await this.fetchInquiryData(this.pagination.currentPage, this.pagination.perPage, this.search);
            }
            this.confirmDeleteDialogVisible = false;
            this.selectedInquiry = null;
            this.isLoading = false;
            this.snackbarMessage = "Inquiry deleted successfully!";
            this.snackbarVisible = true;
          } catch (error) {
            this.isLoading = false;
            console.error("Error deleting Inquiry:", error);
            this.snackbarMessage = "Error occurred while deleting the Inquiry.";
            this.snackbarVisible = true;
        }
      }
    },
  };
  </script>
  <style scoped>
  
  .truncate-text {
    display: inline-block;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .error-message {
    font-size: small;
    color: #B00020;
  }
  
  .v-table--density-default {
      --v-table-header-height: 60px;
      --v-table-row-height: 75px;
  }
  </style>
  