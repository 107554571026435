<template>
  <div>
    <SideBar />
    <HeaderData />
    <v-row align="center">
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="9">
            <v-text-field
              v-model="search"
              label="Search"
              style="width: 300px"
              class="mt-5 ml-4"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
              @click="openDialog"
              class="mt-5 mr-3"
              style="height: 40px; width: 110px;"
              color="primary"
              rounded="xl"
            >
              <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogVisible" max-width="800px">
      <v-card>
        <v-toolbar color="#0277BD" :style="{ fontSize: '25px', paddingLeft:'20px'}" dark flat>
          {{ formData.id ? "Edit" : " Add" }} Blog </v-toolbar>        
          <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="formData.name"
              :rules="nameRules"
              label="Name"
              required
            >
            </v-text-field>
            <label for="datePicker" class="datepicker-label mt-5"
              >Select a date:</label
            >
            <datepicker v-model="formData.date"></datepicker><br/><br/>
            <label for="editor" >Description</label>
            <Editor
              v-model="formData.description"
              editorStyle="height: 320px"
            />
            <!-- {{ v$.formData.description.$error }} fgdgfg -->
            <div v-if="v$.formData.description.$error" class="mt-2 ml-5 error-message">Description is required!</div>
            <!-- <v-text-field
                v-model="formData.date"
                label="Post Date"
              >
              </v-text-field> -->
            <!-- <v-btn @click="openDatePicker()">Open Date Picker</v-btn> -->
            <!-- ref="datePicker" -->
            <!-- <v-date-picker
                v-if="isDatePickerOpen"
                v-model="formData.date"
                label="Select a date"
              ></v-date-picker> -->
          </v-card-text>
          <!-- <input class="mt-5 ml-4" type="file" @change="handleFileUpload" /> -->
          <label class="file-input-label mb-2 ml-4" style="background-color: #0277BD; height:42px;">
                Upload Image
                <input
                  type="file"
                  :key="fileInputKey"
                  accept="image/*" 
                  label="Image Input"
                  @change="handleFileUpload"
                  class="file-input"
                >
            </label>
            <div v-if="isImageSelected && selectedImage && selectedImage.length < 1" class="mb-2 ml-5 error-message">
                  Image is required!
              </div>
              <v-card class="mx-4" variant="tonal" v-if="selectedImage && selectedImage.length">
                <div class="d-flex justify-space-between my-3 mx-2">
                  <div class="mt-2">
                    {{ selectedImage[0].name }}
                  </div>
                    <div>
                      <v-btn
                      @click="removeImage"
                        color="red"
                        >Remove</v-btn
                      >
                    </div>
                </div>
              </v-card>
          <v-card-actions>
            <v-btn v-if="!isLoading" color="primary" @click="validate">{{
              formData.id ? "Update" : "Save"
            }}</v-btn>
            <span v-if="isLoading" class="mr-2">Please Wait</span> 
            <v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
            <v-btn v-if="!isLoading" @click="closeDialog">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialogVisible" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline mt-4">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this Blog?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isLoading"
            color="darken-1"
            text
            @click="confirmDeleteDialogVisible = false"
            >Cancel</v-btn
          >
          <v-btn v-if="!isLoading" color="red darken-1" text @click="confirmDelete">Delete</v-btn>
          <span v-if="isLoading" class="mr-2">Please Wait</span> 
          <v-progress-circular v-if="isLoading" color="primary" indeterminate></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="blogs" class="mt-2">
      <template v-slot:item.description="{ item }">
        <ReadMore v-if="item.description" :text="item.description" :maxLength="55" :title="'Blog Description'"></ReadMore>
      </template>
      <template #bottom>
        <v-pagination
          v-model="pagination.currentPage"
          :length="pagination.lastPage"
          :total-visible="6"
          @input="handlePageChange($event)"
        ></v-pagination>
      </template>
      <template v-slot:item.image="{ item }">
        <img :src="getImageUrl(item.image)" alt="" class="image-set" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon color="blue" @click="editBlog(item)">mdi-pencil</v-icon>
        <v-icon class="ml-3" color="red" @click="deleteBlog(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.date="{ item }">
        {{ formatDate(item.date) }}
      </template>
    </v-data-table>
  </div>
  <v-snackbar v-model="snackbarVisible" location="top right">
    {{ snackbarMessage }}
  </v-snackbar>
</template>
<script>
import SideBar from "../components/SideBar.vue";
import { mapActions } from "vuex";
import ConfirmationDialog from "../components/Common/ConfirmationDialog.vue";
import HeaderData from "@/components/HeaderData.vue";
import Editor from "primevue/editor";
import Datepicker from "vuejs3-datepicker";
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ReadMore from "./ReadMore.vue";

export default {
  components: {
    SideBar,
    ConfirmationDialog,
    HeaderData,
    Editor,
    Datepicker,
    ReadMore
  },

  data() {
    return {
      search: "",
      dialogVisible: false,
      selectedFile: [],
      selectedImage: [],
      valid: true,
      isLoading: false,
      name: "",
      fileInputKey: 0,
      isImageSelected: false,
      nameRules: [(v) => !!v || "Name is required!"],
      description: "",
      descriptionRules: [(v) => !!v || "Description is required!"],      
     formData: {
        id: "",
        name: "",
        description: "",
        date: null,
        image: null,
      },
      headers: [
        { text: "Name", title: "Name", value: "name", sortable: true },
        {
          text: "description",
          title: "Description",
          value: "description",
          sortable: true,
        },
        {
          text: "date",
          title: "Blog Date",
          value: "date",
          sortable: true,
        },
        {
          text: "image",
          title: "Image",
          value: "image",
          sortable: false,
        },
        {
          text: "Actions",
          title: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      confirmDeleteDialogVisible: false,
      selectedBlog: null,
      isDatePickerOpen: false,
      sortBy: [],
      sortDesc: [],
      snackbarVisible: false,
      snackbarMessage: "",
      pagination: {
        lastPage: null,
        currentPage: 1,
        total: null,
        perPage: 10,
      },
    };
  },

  validations() {
    return {
      formData: {
        description: { required },
      }
    }
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  created() {
    this.fetchBlogsData(
      this.pagination.currentPage,
      this.pagination.perPage,
      this.search
    );
    console.log("this.pagination.currentPage", this.pagination.currentPage);
    console.log("this.pagination.perPage", this.pagination.perPage);
  },

  watch: {
    "pagination.currentPage"(newPage) {
      if (typeof newPage !== "undefined") {
        console.log(`Page changed to: ${newPage}`);
        this.fetchBlogsData(newPage, this.pagination.perPage, this.search);
      }
    },

    search(newSearch, oldSearch) {
      if (newSearch !== oldSearch) {
        this.pagination.currentPage = 1;
        this.$nextTick(() => {
          this.fetchBlogsData(
            this.pagination.currentPage,
            this.pagination.perPage,
            newSearch
          );
        });
      }
    },
  },

  beforeRouteLeave() {
    console.log("innnnn");
    this.pagination.currentPage = 1;
  },

  computed: {
    blogs() {
      const blogData = this.$store.state.blogs;
      this.pagination.lastPage = blogData.last_page;
      this.pagination.currentPage = blogData.current_page;
      this.pagination.perPage = blogData.per_page;
      this.pagination.total = blogData.total;
      return this.$store.state.blogs.data;
    },
  },

  methods: {
    ...mapActions(["fetchBlogs"]),
    async fetchBlogsData(page, perPage, search) {
      console.log(
        `Fetching blogs with page=${page}, perPage=${perPage}, search='${search}'`
      );
      search = search || "";
      await this.$store.dispatch("fetchBlogs", {
        page: page,
        perPage: perPage,
        search: search,
      });
    },

    getImageUrl(imageJson) {
      try {
        const imageData = JSON.parse(imageJson) ?? [];
        return imageData[0]?.url;
      } catch (error) {
        console.error("Error parsing image JSON:", error);
        return "";
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-based.
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },

    handleFileUpload(event) {
      var selectedFiles = event.target.files || event;
      this.selectedFile = []; 
      this.selectedImage = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        this.selectedFile.push(selectedFiles[i]);
        this.selectedImage.push({
          name: selectedFiles[i].name
        });
      }
    },

    removeImage() {
      this.isImageSelected = true;
      this.selectedFile = [];
      this.selectedImage = [];
      this.fileInput = null;
      this.fileInputKey++;
    },

    openDatePicker() {
      // Access the date picker component using its ref
      // const datePicker = this.$refs.datePicker;
      // Call the open() method to open the date picker
      // datePicker.open();
      this.isDatePickerOpen = !this.isDatePickerOpen;
    },

    stripTags(htmlString) {
      const doc = new DOMParser().parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    },

    async validate() {
      this.isImageSelected = true;
      const validate = await this.$refs.form.validate();
      await this.v$.$validate();
      if (validate.valid && !this.v$.$error && this.selectedImage.length) {
        this.saveBlog();
      }
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.formData = {
        id: "",
        name: "",
        description: "",
        date: null,
        image: null,
      };
      this.selectedFile = [];
      this.selectedImage = []; 
      this.isImageSelected = false;
      if (this.v$) {
        this.v$.$reset();
       }
    },
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
      this.fetchBlogsData(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.search
      );
    },
    // async saveBlog() {
    //   try {
    //     let message;
    //     if (this.formData.id) {
    //       await this.$store.dispatch("updateBlog", this.formData);
    //       this.fetchBlogsData(
    //         this.pagination.currentPage,
    //         this.pagination.perPage,
    //         this.search
    //       );
    //       message = "Blog updated successfully!";
    //     } else {
    //       await this.$store.dispatch("addBlog", {
    //         itemData: this.formData,
    //         page: this.pagination.currentPage,
    //         perPage: this.pagination.perPage,
    //       });
    //       message = "Blog added successfully!";
    //     }
    //     this.snackbarMessage = message;
    //     this.snackbarVisible = true;
    //     this.closeDialog();
    //   } catch (error) {
    //     console.error("Error saving Blog:", error);
    //     this.snackbarMessage = "Error occurred while saving the Blog.";
    //     this.snackbarVisible = true;
    //   }
    // },

    // async saveBlog() {
    //   this.isLoading = true;
    //   const formData = new FormData();
    //   formData.append("name", this.formData.name);
    //   formData.append("description", this.formData.description);

    //   // Convert date to the expected format 'Y-m-d'
    //   const date = new Date(this.formData.date);
    //   const formattedDate = date.toISOString().split("T")[0]; // 'YYYY-MM-DD'
    //   formData.append("date", formattedDate);

    //   if (this.selectedFile && this.selectedFile.length) {
    //     formData.append("image", this.selectedFile);
    //   }

    //   let actionType = this.formData.id ? "updateBlog" : "addBlog";
    //   let payload = {
    //     formData: formData,
    //     page: this.pagination.currentPage,
    //     perPage: this.pagination.perPage,
    //   };

    //   if (this.formData.id) {
    //     payload.id = this.formData.id;
    //   }

    //   try {
    //     await this.$store.dispatch(actionType, payload);
    //     this.selectedFile = [];
    //     this.isLoading = false;
    //     this.snackbarMessage = this.formData.id
    //       ? "Blog updated successfully!"
    //       : "Blog added successfully!";
    //     this.snackbarVisible = true;
    //     this.closeDialog();
    //   } catch (error) {
    //     this.isLoading = false;
    //     console.error("Error saving blog:", error);
    //     this.snackbarMessage = "Error occurred while saving the blog.";
    //     this.snackbarVisible = true;
    //   }
    // },

    async saveBlog() {
      this.isLoading = true;
      const date = new Date(this.formData.date);
      const formattedDate = date.toISOString().split("T")[0]; // 'YYYY-MM-DD'
      // formData.append("date", formattedDate);
      let formData = {
        name: this.formData.name,
        description: this.formData.description,
        date: formattedDate
      };

      if (this.formData.id) {
        formData.id = this.formData.id;
      }

      if (this.selectedFile && this.selectedFile.length) {
        formData.image = this.selectedFile;
      }

      if (formData.id) {
        await this.$store.dispatch("updateBlog", formData);
        this.fetchBlogsData(
          this.pagination.currentPage,
          this.pagination.perPage,
          this.search
        );
      } else {
        await this.$store.dispatch("addBlog", {
          formData: formData,
          page: this.pagination.currentPage,
          perPage: this.pagination.perPage,
        });
      }

      try {
        this.selectedFile = [];
        this.isLoading = false;
        this.snackbarMessage = this.formData.id
          ? "Blog updated successfully!"
          : "Blog added successfully!";
        this.snackbarVisible = true;
        this.closeDialog();
      } catch (error) {
        this.isLoading = false;
        console.error("Error saving blog:", error);
        this.snackbarMessage = "Error occurred while saving the blog.";
        this.snackbarVisible = true;
      }
    },

    editBlog(item) {
      this.formData = {
        id: item.id,
        name: item.name,
        description: item.description,
        isActive: item.isActive,
        date: item.date,
        image: null,
      };
      this.selectedImage = JSON.parse(item.image);
      this.selectedFile = [];
      this.dialogVisible = true;
    },

    deleteBlog(item) {
      this.selectedBlog = item;
      this.confirmDeleteDialogVisible = true;
    },
    async confirmDelete() {
      try {
        this.isLoading = true;
        const BlogId = this.selectedBlog.id;
        await this.$store.dispatch("deleteBlog", BlogId);
        this.fetchBlogsData(
          this.pagination.currentPage,
          this.pagination.perPage,
          this.search
        );
        //   this.$store.commit("DELETE_BLOG", BlogId);
        this.confirmDeleteDialogVisible = false;
        this.pagination.currentPage = 1;
        this.selectedBlog = null;
        this.isLoading = false;
        this.snackbarMessage = "Blog deleted successfully!";
        this.snackbarVisible = true;
      } catch (error) {
        this.isLoading = false;
        console.error("Error deleting Blog:", error);
        this.snackbarMessage = "Error occurred while deleting the Blog.";
        this.snackbarVisible = true;
      }
    },
  },
};
</script>
<style scoped>
.file-input-label {
  background-color: #f6f6f6;
  /* color: white; */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  color: white;
}
.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.truncate-text {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datepicker-label {
  display: block;
  margin-bottom: 5px; /* Adjust the space between label and datepicker */
  /* Add more styling as needed */
}

.image-set {
  margin-top: 5px;
  max-height: 85px;
  max-width: 85px;
}

.error-message {
  font-size: small;
  color: #B00020;
}

.v-table--density-default {
    --v-table-header-height: 60px;
    --v-table-row-height: 75px;
}
</style>
